import { useToast } from '@chakra-ui/react';
import Firebase from '../common/libs/firebase';
import { setLogRocketUser } from '../common/libs/logRocketForArticleUpload';
import monitoring from '../common/libs/monitoring';
import { useAppContext } from '../contexts/app';
import useApiClient from './useApiClient';
import useAppRouter from './useAppRouter';
import useIdApiClient from './useIdApiClient';

export function useLogout() {
  const { apiClient } = useApiClient();
  const { idApiClient } = useIdApiClient();
  const router = useAppRouter();
  const { user } = useAppContext();
  const toast = useToast();

  const logout = async (
    redirectUrl?: string,
    mailAddr?: string,
    accountIntegrationCompleted?: boolean,
  ) => {
    try {
      let res: Response | null = null;
      if (user) {
        // Slideログアウト
        res = await apiClient('/api/logout', {
          method: 'POST',
        });
      }
      // eslint-disable-next-line no-console
      console.log(`@@@@@@ SLIDE LOGOUT: result = ${JSON.stringify(res)}`);
      if (!user || res?.ok) {
        if (await Firebase.getIdToken()) {
          // ID基盤ログアウト
          const res2 = await idApiClient('logout', {
            method: 'POST',
          });
          // eslint-disable-next-line no-console
          console.log(`@@@@@@ ID LOGOUT: result = ${JSON.stringify(res2)}`);
        }

        // Firebaseログアウト
        Firebase.logout();

        router.pushLogin({
          redirectUrl,
          mailAddr,
          accountIntegrationCompleted,
        });
        monitoring.setUser();
        setLogRocketUser();
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(`@@@@@@ LOGOUT ERROR = ${e}`);
      toast({
        title: 'ログアウトできませんでした。',
        description: 'しばらく待って再度お試しください。',
        status: 'error',
        isClosable: true,
        variant: 'subtle',
      });
    }
  };

  return {
    logout,
  };
}
